<template>
  <div>
    <b-row>
      <b-col
        cols="9"
        class="mx-auto"
      >
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          layout="vertical"
          finish-button-text="Add Another User"
          back-button-text="Previous"
          class="wizard-vertical mb-3"
          :start-index="0"
          @on-complete="formSubmitted"
        >

          <!-- access tab -->
          <tab-content
            ref="AccessTab"
            title="Access"
            :before-change="processAccesTab"
          >
            <validation-observer ref="rulesAccesTab">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Access
                  </h5>
                  <small class="text-muted">
                    Enter The Access Details.
                  </small>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="First Name *"
                    label-for="firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="firstname"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="forename"
                        placeholder="John"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Last Name *"
                    label-for="lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="surname"
                        placeholder="Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email *"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="john.doe@email.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Account Type *"
                    label-for="account_type"
                  >
                    <b-form-select
                      id="account_type"
                      v-model="accType"
                      :options="['Client', 'Affiliate', 'Moderator', 'Finance']"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Password *"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:6"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        type="password"
                        placeholder="Minimum of 6 characters"
                        vid="password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Confirm Password *"
                    label-for="confirmpassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="confirmpassword"
                      rules="required|confirmed:password"
                    >
                      <b-form-input
                        id="confirmpassword"
                        v-model="confirmpassword"
                        type="password"
                        placeholder="Re-type Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- details tab -->
          <tab-content
            ref="DetailsTab"
            title="Details"
            :before-change="processDetailsTab"
          >
            <validation-observer ref="rulesProcessTab">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Details
                  </h5>
                  <small class="text-muted">NOTE: For EU-based companies, please provide the VAT #. For any other companies, please provide the Company Registration #.</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <!-- <b-form-group
                    label="Turn ON for Company option, otherwice OFF for Individual"
                    label-for="companyIndividual"
                  >
                    <b-form-checkbox
                      id="companyIndividual"
                      v-model="companyIndividual"
                      switch
                    />
                  </b-form-group> -->
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label="Please select option below:"
                  >
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="companyIndividual"
                      :options="copersonOption"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options-1"
                      @change="changeCompanyIndividual"
                    />
                  </b-form-group>
                  <hr>
                </b-col>
              </b-row>
              <b-row
                v-if="companyIndividual"
                ref="showCompany"
              >
                <b-col md="6">
                  <b-form-group
                    label="Company Name *"
                    label-for="company"
                  >
                    <validation-provider
                      #default="{ errors }"
                      ref="companyRule"
                      name="company"
                      rules="required"
                    >
                      <b-form-input
                        id="company"
                        v-model="company"
                        placeholder="My Company Ltd."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="VAT/Reg # *"
                    label-for="vatno"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="vatno"
                      rules="required"
                    >
                      <b-input-group>
                        <b-form-input
                          id="vatno"
                          v-model="vatno"
                          placeholder="VAT/Reg Number"
                          @blur="validateVAT"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="success"
                            title="Validate VAT. NOTE: For EU-based companies, please provide the VAT #. For any other companies, please provide the Company Registration #."
                            @click="validateVATApply"
                          >
                            <feather-icon
                              icon="SearchIcon"
                              size="14"
                              title="Validate VAT. NOTE: For EU-based companies, please provide the VAT #. For any other companies, please provide the Company Registration #."
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        class="text-danger"
                        style="margin-top:-10px"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Website"
                    label-for="website"
                  >
                    <b-form-input
                      id="website"
                      v-model="website"
                      placeholder="http://mycompany.com"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Country *"
                    label-for="lcountry"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="selectedCountry"
                      rules="required"
                    >
                      <v-select
                        id="lcountry"
                        v-model="selectedCountry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countries"
                        label="name"
                        @input="setSelectedCountryCode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6">
                  <b-form-group
                    label-for="region"
                    label="Regional Unit *"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="selectedRegion"
                      rules=""
                    >
                      <v-select
                        id="region"
                        v-model="selectedRegion"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="regions"
                        label="name"
                        @input="setSelectedRegion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <b-col md="6">
                  <b-form-group
                    label="Address 1 *"
                    label-for="address1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="address1"
                      rules="required"
                    >
                      <b-form-input
                        id="address1"
                        v-model="address1"
                        placeholder="Address 1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="City / Location *"
                    label-for="city"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                    >
                      <b-form-input
                        id="city"
                        v-model="city"
                        placeholder="City / Location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Post Code *"
                    label-for="postcode"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="postcode"
                      rules="required"
                    >
                      <b-form-input
                        id="postcode"
                        v-model="postcode"
                        placeholder="Post Code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Address 2"
                    label-for="address2"
                  >
                    <b-form-input
                      id="address2"
                      v-model="address2"
                      placeholder="Address 2 (optional)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- account tab -->
          <tab-content
            ref="AccountTab"
            title="Account"
            :before-change="processAccountTab"
          >
            <validation-observer ref="rulesAccountTab">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Account
                  </h5>
                  <small class="text-muted">Final steps for the registration.</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-for="package"
                    label="Package *"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="selectedPackage"
                      rules="required"
                    >
                      <v-select
                        id="package"
                        v-model="selectedPackage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="packageOption"
                        label="name"
                        @input="setSelectedPackage"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Phone #"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      placeholder="+81 Phone Number"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Skype ID"
                    label-for="skype"
                  >
                    <b-form-input
                      id="skype"
                      v-model="skype"
                      placeholder="Your Skype ID"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h5 class="mb-2">
                    Package Details
                  </h5>
                  <p><small>PACKAGE CHOICE</small></p>

                  <!-- <b-form-group
                    label="Turn ON for Conversion option otherwice OFF for Click"
                    label-for="clickConversion"
                  >
                    <b-form-checkbox
                      id="clickConversion"
                      v-model="clickConversion"
                      switch
                      @change="changeClickConversion"
                    /><small>Included: ({{ this.clickConversionData.toLocaleString() }}) clicks</small>
                  </b-form-group> -->
                  <new-package :selected-package="selectedPackageAllFields" />
                  <hr>
                  <b-form-group
                    label="Subscribe to the news letter"
                    label-for="subscribe"
                  >
                    <b-form-checkbox
                      id="subscribe"
                      v-model="subscribe"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- account tab -->
          <tab-content
            ref="FinishTab"
            title="Completed"
          >
            <b-row>
              <b-col md="12">
                <center>
                  <p>An activation is sent. It contains a message with an ACCOUNT ACTIVATION button/link to {{ emailedto }}.</p>
                  <br>
                  <p>
                    <b>Note:</b> Use the 'Previous' button if you want to update the user's information. <span v-f="id">Or, <a
                      href="#"
                      @click="impersonate"
                    >click here to impersonate.</a></span>
                  </p>
                </center>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from '@/libs/axios' // eslint-disable-line
import {
  BRow, BInputGroup,
  BCol, BInputGroupAppend,
  BFormGroup, BButton,
  BFormInput, BFormSelect,
  BFormCheckbox, BFormRadioGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import NewPackage from './NewPackage.vue'

const jsonpAdapter = require('axios-jsonp')

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    'new-package': NewPackage,
  },
  data() {
    return {

      // user ID used in each tab
      id: 0,
      uid: 0,

      // tab 1
      forename: '',
      surname: '',
      email: '',
      password: '',
      confirmpassword: '',
      accType: 'Client',

      // tab 2
      showCompany: '',
      companyIndividual: 1,
      company: '',
      vatno: '',
      website: '',
      selectedCountry: {},
      selectedRegion: '1817',
      defaultPackageID: 14,
      region: '',
      address1: '',
      address2: '',
      city: '',
      postcode: '',
      coperson: 'individual',
      copersonOption: [{ text: 'Company', value: 1 }, { text: 'Individual', value: 0 }],
      isVatIDValid: false,

      // tab 3
      clickConversion: true,
      selectedPackage: {},
      selectedPackageAllFields: {},
      packageOption: [],
      clickConversionData: 0,
      subscribe: true,
      paymode: 'conversions',
      phone: '',
      skype: '',

      // message variable
      emailedto: '',

      vatResData: null,
    }
  },
  computed: {
    countries() {
      return this.$store.state.auth.countries
    },
    regions() {
      return this.$store.state.auth.regions
    },
    packages() {
      return this.$store.state.auth.activePackages
    },
  },
  mounted() {
    // const refreshId = setInterval(() => {
    //   if (this.packages) {
    //
    //     clearInterval(refreshId)
    //   }
    // }, 200)

    this.setPackagesOption()
    this.setDefaultCountryAndRegion()
  },
  methods: {
    setDefaultCountryAndRegion() {
      this.country = 'JP'
      this.selectedCountry = { name: 'Japan', code: 'JP' }
      this.selectedRegion = 'Aichi'
      this.region = 1817
    },

    setPackagesOption() {
      Object.keys(this.packages).forEach(key => {
        const option = {}
        const code = Number(this.packages[key].uid)
        option.id = this.packages[key].id
        if (code === 6003 || code === 5003) option.name = `${this.packages[key].name} (casino)`
        else option.name = this.packages[key].name
        this.packageOption.push(option)
      })
      this.setSelectedPackage()

      // set default choice
      this.selectedPackage = this.packages.find(obj => obj.id === this.defaultPackageID)
      this.selectedPackageAllFields = this.selectedPackage
    },

    setSelectedCountryCode() {
      const country = this.selectedCountry.code
      const payload = { country }
      this.$store.dispatch('auth/updateRegions', payload)
        .then(res => { this.selectedRegion = ''}) // eslint-disable-line
    },
    setSelectedRegion() {
      this.region = this.selectedRegion.id
    },

    async processAccesTab() {
      let isPassed = await this.$refs.rulesAccesTab.validate().then(success => success)
      if (isPassed === false) return false

      let isModerator = 0
      let isFinance = 0
      let isAffiliate = 0

      if (this.accType === 'Affiliate') isAffiliate = 1
      else if (this.accType === 'Moderator') isModerator = 1
      else if (this.accType === 'Finance') isFinance = 1

      const payload = {
        uid: this.uid,
        forename: this.forename,
        surname: this.surname,
        email: this.email,
        email_confirm: this.email,
        password: this.password,
        password_confirm: this.confirmpassword,
        moderator: isModerator,
        finance: isFinance,
        is_affiliate: isAffiliate,
      }

      if (this.uid === 0) {
        isPassed = await this.serverDataValidator('validate_access', payload)
        if (isPassed === false) return false
      }

      // do step 1 (under tab 1)
      const uri = '/api/pre_register/access'
      isPassed = await this.registrationSteps(uri, payload)

      return isPassed
    },

    changeCompanyIndividual() {
      if (this.companyIndividual === 1) this.coperson = 'company'
      else this.coperson = 'individual'
    },

    async processDetailsTab() {
      let isPassed = await this.$refs.rulesProcessTab.validate().then(success => success)
      const euCountries = [
        'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE',
      ]

      let isEuCountry = false
      let allowNoVat = false
      euCountries.forEach(code => {
        if (code === this.selectedCountry.code) isEuCountry = true
      })

      if (this.companyIndividual === 1 && isEuCountry === false && !this.vatno) {
        allowNoVat = await this.confirmationAlert('VAT/Registration number is Empty. Are you sure that your company does not have VAT or Registration Number?')
      }

      if (isPassed === false && this.companyIndividual === 0) return false
      if (this.companyIndividual === 1 && allowNoVat.isConfirmed === false) return false
      if (this.companyIndividual === 1 && isEuCountry && !this.vatno) return false

      let isModerator = 0
      let isFinance = 0
      let isAffiliate = 0

      if (this.accType === 'Affiliate') isAffiliate = 1
      else if (this.accType === 'Moderator') isModerator = 1
      else if (this.accType === 'Finance') isFinance = 1

      const payload = {
        uid: this.uid,
        forename: this.forename,
        surname: this.surname,
        email: this.email,
        password: this.password,
        moderator: isModerator,
        finance: isFinance,
        is_affiliate: isAffiliate,
        website: this.website,
        company: this.company,
        country: this.selectedCountry.code,
        region: this.region,
        city: this.city,
        address1: this.address1,
        address2: this.address2,
        postcode: this.postcode,
        vatno: this.vatno,
        coperson: this.coperson,
        vat_checked: this.isVatIDValid,
      }

      isPassed = await this.serverDataValidator('validate_details', payload)
      if (isPassed === false) return false

      // do step 2 (under tab 2)
      const uri = '/api/pre_register/details'
      isPassed = await this.registrationSteps(uri, payload)

      return isPassed
    },
    async processAccountTab() {
      const isValidated = await this.$refs.rulesAccountTab.validate().then(success => success)
      if (isValidated === false) return false

      let isSubcribe = 0
      if (this.subscribe) isSubcribe = 1

      const payload = {
        uid: this.uid,
        paymode: this.paymode,
        phone: this.phone,
        skype: this.skype,
        news_sub: isSubcribe,
        package: this.selectedPackageAllFields.uid,
      }

      // do step 3 (under tab 3)
      const uri = '/api/pre_register/account'
      let isPassed = false
      isPassed = await this.registrationSteps(uri, payload)
      if (isPassed) {
        this.emailedto = this.email
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Form Submitted',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }

      return isPassed
    },

    async formSubmitted() {
      this.$router.go(this.$router.currentRoute)
    },

    async registrationSteps(uri, payload) {
      const response = axios.post(uri, payload)
      const responseData = await response.then(result => result).catch(error => error)

      if (typeof responseData.status === 'undefined'
          || responseData.status === 'Failed') {
        let error_message = 'There is a problem encountered in the server. Please try again.'
        if (typeof responseData.response.data.message !== 'undefined') error_message = responseData.response.data.message

        this.notify({
          text: error_message,
          variant: 'danger',
        })
        return false
      }

      if (responseData.status === 200 && typeof responseData.data.uid !== 'undefined') {
        this.uid = responseData.data.uid
        if (typeof responseData.data.id !== 'undefined') this.id = responseData.data.id
      }
      return true
    },

    async serverDataValidator(uri, payload) {
      const response = axios.post(`/api/pre_register/${uri}`, payload)
      const responseData = await response.then(result => result).catch(error => error)

      if (typeof responseData.status === 'undefined') {
        const msg = JSON.parse(responseData.request.response)
        const key = Object.keys(msg)
        const ErrorMsg = msg[key].toString()
        this.notify({
          text: ErrorMsg,
          variant: 'danger',
        })
        return false
      }
      return true
    },

    setSelectedPackage() {
      const isEmpty = Object.keys(this.selectedPackage).length === 0

      if (!isEmpty) {
        this.defaultPackageID = this.selectedPackage.id
      }

      this.selectedPackageAllFields = this.packages.find(obj => obj.id === this.defaultPackageID)
      this.changeClickConversion()
    },
    changeClickConversion() {
      if (this.clickConversion) {
        this.clickConversionData = this.selectedPackageAllFields.conversions
        this.paymode = 'conversions'
      } else {
        this.clickConversionData = this.selectedPackageAllFields.clicks
        this.paymode = 'clicks'
      }
    },
    impersonate() {
      this.$store.dispatch('auth/impersonate', this.id).then(() => { window.location = '/' })
    },
    async validateVAT() {
      let vat_number = this.vatno
      vat_number.trim()
      vat_number = vat_number.replace(/ /g, '')
      vat_number = vat_number.replace('-', '')
      this.vatno = vat_number
      const accessKey = 'c14cce7738efb19a9728292b361a0685'

      let geturl = 'https://apilayer.net/api/validate'
      geturl = `${geturl}?vat_number=${vat_number}`
      geturl = `${geturl}&access_key=${accessKey}`

      const resVat = axios({
        url: geturl,
        adapter: jsonpAdapter,
        method: 'get',
      })

      const resVatData = await resVat.then(result => result).catch(error => error)
      const resData = resVatData.data

      if (resData.valid) {
        // return false
        this.isVatIDValid = true
        this.vatResData = resData
      }
    },
    validateVATApply() {
      if (!this.vatResData) this.validateVAT()
      if (this.isVatIDValid) {
        const resData = this.vatResData
        if (resData.company_address) this.address1 = resData.company_address
        if (resData.country_code) this.country = resData.country_code
        if (resData.country_code) this.selectedCountry = this.countries.find(obj => obj.code === this.country)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
